import React from 'react';
import './Hero.css';
import { FaReact, FaHtml5, FaCss3Alt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { SiNextdotjs } from "react-icons/si";

const Hero = () => {
  return (
    <section className='hero-container'>
        <div className='hero-content'>
            <h2> Building Digital Experiences</h2>
            <p>
                My name is <span className='nombre'>Matias Rivera Zahn</span>, Passionate Junior Fullstack Developer <br/> Transforming Ideas into 
                Visually Stunning Web Solutions.
            </p>
        </div>
        <div className='hero-img'>
            <img className='img' src="/assets/imagenprog.jpg" alt='Imagen Programador'/>
            <div className='imgicons'>
                <div className='tech-icon'>
                    <FaReact className='icon' style={{ color: '#61DAFB' }} />
                </div>
                <div className='tech-icon'>
                    <FaHtml5 className='icon' style={{ color: '#E34C26' }} />
                </div>
                <div className='tech-icon'>
                    <FaCss3Alt className='icon' style={{ color: '#1572B6' }} />
                </div>
                <div className='tech-icon'>
                    <IoLogoJavascript className='icon' style={{ color: '#F7DF1E' }} />
                </div>
                <div className='tech-icon'>
                    <SiNextdotjs className='icon' style={{ color: '#000000' }} />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero;