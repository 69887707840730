import React from 'react';
import './ContactMe.css';
import ContactInfoCard from './ContactInfoCard/ContactInfoCard';

const ContactMe = () => {
  return (
    <section className='contact-container'>
      <h5>Contact Me</h5>
      <div className='contact-content'>
        <div style={{ flex: 1 }}>
          <ContactInfoCard iconType='email' url="mriverazahn@gmail.com" />
          <ContactInfoCard iconType='github' url="https://github.com/MatiasRZ7" />
          <ContactInfoCard iconType='linkedin' url="https://www.linkedin.com/in/matiasriverazahn/" />
        </div>
      </div>
    </section>
  );
}

export default ContactMe;