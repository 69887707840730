export const SKILLS = [
    {
        title: "Frontend",
        icon: "../../assets/frontendicon.png",
        skills: [
            {skill: "HTML", percentage: "80%"},
            {skill: "CSS3/Tailwinds", percentage: "80%"},
            {skill: "JavaScript", percentage: "80%"},
            {skill: "React", percentage: "80%"},
            {skill: "Next.js", percentage: "80%"},
            {skill: "Typescript", percentage: "80%"},
        ],
    },
    {
        title: "Tools",
        icon: "../../assets/tools.png.svg",
        skills: [
            {skill: "Git & Github", percentage: "70%"},
            {skill: "VS Code", percentage: "75%"},
            {skill: "Responsive Design", percentage: "70%"},
            {skill: "AI", percentage: "80%"},
        ],
    },
    {
        title: "Soft Skills",
        icon: "../../assets/skillsicon.png",
        skills: [
            {skill: "Problem-solving", percentage: "85%"},
            {skill: "Collaboration", percentage: "85%"},
            {skill: "Attention to detail", percentage: "80%"},
            {skill: "Analytical thinking", percentage: "80%"},
        ],
    },
    {
        title: "Backend",
        icon: "../../assets/backend.png",
        skills: [
            {skill: "Node.js", percentage: "70%"},
            {skill: "Express.js", percentage: "60%"},
            {skill: "MongoDB", percentage: "70%"},
            {skill: "Firebase", percentage: "70%"},

        ],
    },
];
export const PROYECTS = [
    {
        title: "Fullstack Marketplace Ecommerce",
        description: "A marketplace ecommerce website that allows users to buy digital products and services using the Stripe payment gateway. Admins can manage products and orders.",
        image: "../../assets/marketplacegranaina.png",
        link: "https://marketplacegranaina.vercel.app",
        skills: ['react', 'typescript', 'nextjs', 'tailwind', 'stripe', 'supabase', 'kinde', 'shadcn'], 
    },
    {
        title: "Fullstack Ecommerce website with admin dashboard",
        description: "A fullstack ecommerce website for a client with an admin dashboard that allows users to browse tours, add them to a cart, and make purchases. Admin can manage products, orders, and users.",
        image: "../../assets/screenshottienda.png",
        link: "https://rivieramayatour.com",
        skills: ['react', 'typescript', 'nextjs', 'tailwind', 'mongodb', 'stripe', 'clerk'],
    },
    {
        title: "Fullstack rental website",
        description: "Fullstack rental website that allows users to browse and rent properties. Users can also manage their property and bookings.",
        image: "../../assets/rental.png",
        link: "https://rentaldream.onrender.com",
        skills: ['react', 'javascript', 'sass', 'mongodb', 'nodejs', 'express'],
    },
    {
        title: "Ecommerce website",
        description: "A user-friendly ecommerce website offering a wide range of products across multiple categories.",
        image: "../../assets/tienda online.png",
        link: "https://eshopreact.netlify.app",
        skills: ['react', 'javascript', 'tailwind'],
    },
    {
        title: "Movie Rate app",
        description: "A dynamic application that interacts with a movie API to provide users with a vast array of movies and their details.",
        image: "../../assets/movieapp2.png",
        link: "https://appreactmovie.netlify.app",
        skills: ['react', 'typescript', 'css'],
    },
    {
        title: "Kanban Board app",
        description: "A user-friendly application to manage tasks in a visual way. It allows users to create, update, and move tasks between different stages.",
        image: "../../assets/kanban.png",
        link: "https://kanbanstyletask.netlify.app",
        skills: ['react', 'javascript', 'tailwind'],
    },
    {
        title: "Crypto API app",
        description: "Dynamic application that interacts with a cryptocurrency API to fetch and display real-time data.",
        image: "../../assets/crypto.png",
        link: "https://react-crypto1.netlify.app",
        skills: ['react', 'javascript', 'css'],
    },
    {
        title: "Recipe app",
        description: "Interactive application that leverages a recipe API to provide users with a vast array of recipes. ",
        image: "../../assets/recipe.png",
        link: "https://mealsitereact.netlify.app",
        skills: ['react', 'javascript', 'css'],
    },
    {
        title: "Weather app",
        description: "A dynamic application that utilizes a weather API to provide users with real-time weather updates. ",
        image: "../../assets/weather.png",
        link: "https://apiappweatherr.netlify.app",
        skills: ['react', 'javascript', 'css'],
    },
    {
        title: "Expense-tracker app",
        description: "A financial tool designed to help users monitor and manage their expenses effectively.",
        image: "../../assets/expensetrack.png",
        link: "https://reactexpensetrack.netlify.app",
        skills: ['react', 'javascript', 'css'],
    },
    {
        title: "Basic ecommerce app",
        description: "It provides a simple platform for users to browse products and add them to a shopping cart.",
        image: "../../assets/techshop.png",
        link: "https://basicreactecommerce.netlify.app",
        skills: ['react', 'javascript', 'css'],
    },
    {
        title: "To-Do app",
        description: "Simple application that allows users to create a list of tasks, mark tasks as completed, and delete tasks from the list.",
        image: "../../assets/todoapp.png",
        link: "https://listtodoappreact.netlify.app",
        skills: ['react', 'javascript', 'css'],
    },

]