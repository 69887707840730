import React from "react";
import "./ProyectsCard.css";
import { FaReact } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaCss3Alt } from "react-icons/fa";
import { RiNextjsFill } from "react-icons/ri";
import { SiTailwindcss } from "react-icons/si";
import { BiLogoMongodb } from "react-icons/bi";
import { FaSass } from "react-icons/fa";
import { FaNode } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { FaCcStripe } from "react-icons/fa";
import { SiClerk } from "react-icons/si";
import { RiSupabaseFill } from "react-icons/ri";
import { SiKeystone } from "react-icons/si";
import { SiShadcnui } from "react-icons/si";

const ProyectsCard = ({ details }) => {
  const skillIcons = {
    react: <FaReact className="work-icon" />,
    javascript: <IoLogoJavascript className="work-icon" />,
    css: <FaCss3Alt className="work-icon" />,
    nextjs: <RiNextjsFill className="work-icon" />,
    tailwind: <SiTailwindcss className="work-icon" />,
    mongodb: <BiLogoMongodb className="work-icon" />,
    sass : <FaSass className="work-icon" />,
    nodejs: <FaNode className="work-icon" />,
    express: <SiExpress className="work-icon" />,
    typescript: <SiTypescript className="work-icon" />,
    stripe: <FaCcStripe className="work-icon" />,
    clerk: <SiClerk className="work-icon" />,
    supabase: <RiSupabaseFill className="work-icon" />,
    kinde: <SiKeystone className="work-icon" />,
    shadcn: <SiShadcnui className="work-icon" />
    
  };

  return (
    <div className="work-experience-card">
      <h6 className="work-name">{details.title}</h6>
      <div className="work-description">{details.description}</div>
      <img className="work-img" src={details.image} alt={details.title} />
      <a
        className="work-url"
        href={details.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        See proyect
      </a>
      <div className="work-skills">
        Skills used:
        {details.skills ? details.skills.map((skill, index) => <div key={index}>{skillIcons[skill]}</div>) : null}
      </div>
    </div>
  );
};

export default ProyectsCard;
