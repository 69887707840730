import React from 'react';
import './ContactInfoCard.css';
import { MdEmail } from "react-icons/md";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const ContactInfoCard = ({ iconType, url }) => {
  return (
    <div className='contact-details-card'>
      <a href={iconType === 'email' ? `mailto:${url}` : url} target={iconType !== 'email' ? "_blank" : "_self"} rel="noopener noreferrer" className='icon'>
        {iconType === 'email' && <MdEmail size={30} />}
        {iconType === 'github' && <FaGithub size={30} />}
        {iconType === 'linkedin' && <FaLinkedin size={30} />}
      </a>
    </div>
  );
}

export default ContactInfoCard;